import isMobile from "@/utils/is-mobile";

import type { Notifications } from "./types";

const title = "Qikbee PWA";

const email = "m.loebe@lemongrass-software.de";

const messages = {
  app: {
    crash: {
      title: "Anscheinend ist etwas schiefgelaufen. Sie können:",
      options: {
        email: `Den Autor per E-Mail unter - ${email} kontaktieren`,
        reset: "Hier drücken, um die Anwendung zurückzusetzen",
      },
    },
  },
  loader: {
    fail: "Problem beim laden dieser Komponente",
  },
  images: {
    failed: "Es ist ein Fehler beim Laden des Bildes aufgetreten",
  },
  404: "Fehler 404 – Seite nicht gefunden",
};

const dateFormat = "MMMM DD, YYYY";

const notifications: Notifications = {
  options: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    autoHideDuration: 6000,
  },
  maxSnack: isMobile ? 3 : 4,
};

const loader = {
  // no more blinking in your app
  delay: 300, // if your asynchronous process is finished during 300 milliseconds you will not see the loader at all
  minimumLoading: 700, // but if it appears, it will stay for at least 700 milliseconds
};

const defaultMetaTags = {
  image: "/cover.png",
  description: "Starter kit for modern web applications",
};

export {
  dateFormat,
  defaultMetaTags,
  email,
  loader,
  messages,
  notifications,
  title,
};
